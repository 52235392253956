$blackMlkColor: #100f0f;
$whiteMlkColor: #fefefe;
$lightBlueMlkColor: #c1d9ff;
$gradientMlkBlueColor: #e0ecff;
$darkBlueMlkColor: #365b97;
$linearGradientBlueMlkColor: #f2f7ff;
$greyMlkColor: #b8b8b8;
$lightGreyMlkColor: #f1f1f1;
$mediumLightGreyMlkColor: #e7e7e7;
$darkGreyMlkColor: #707070;
$redMlkColor: #ea0b0b;
