// Custom style and mixins
@import '@angular/cdk/overlay-prebuilt.css';

@import './stylesheets';

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'old_css_to_delete_after';

/**
NB : For the media queries,
we use boostrap breakpoint. You can refer
to it on this url : https://getbootstrap.com/docs/5.0/layout/breakpoints/
*/


html,
body {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

body {
  font-family: 'Open Sans';
  background-color: $whiteMlkColor;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  overflow: auto;
}

.mlk-section {
  padding: 8rem 1rem;

  & {
    @include media-breakpoint-down(md) {
      padding: 5rem 1rem;
    }
  }
}

.mlk-section-web-app {
  padding: 7rem 1rem;

  & {
    @include media-breakpoint-down(md) {
      padding: 3rem 1rem;
    }
  }
}

.bi.bi-search {
  cursor: pointer;
}

.mlk-big-logo {
  width: 100%;
}

input:focus {
  box-shadow: none;
}

.bi.bi-search {
  cursor: pointer;
}

.mlk-section-title {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 88.92px;
  margin-bottom: 3rem;

  & {
    @include media-breakpoint-down(sm) {
      font-size: 42px;
      line-height: 50px;
    }

    @include media-breakpoint-only(xs) {
      font-size: 35px;
      line-height: 50px;
    }

    @include media-breakpoint-only(md) {
      font-size: 45px;
    }

    @include media-breakpoint-only(lg) {
      font-size: 45px;
    }
  }
}

.mlk-sub-title {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41.5px;
}

.mlk-menu-link {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: $blackMlkColor;
}

.mlk-menu-link-active,
.nav-link:hover,
.nav-link:focus {
  @extend .mlk-menu-link;
  color: $darkBlueMlkColor;
  font-weight: bold;
}

.navbar-toggler {
  width: 1em;
  height: 1em;

  & {
    @include media-breakpoint-up(md) {
      width: 2em;
      height: 1.5em;
    }
  }
}

.mlk-menu-link-icon {
  font-size: 1.2rem;

  & {
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }
  }
}

.mlk-menu-link-icon:hover,
.nav-link.mlk-menu-link-icon:hover,
.nav-link.mlk-menu-link-icon:focus {
  font-size: 1.3rem;
  color: $blackMlkColor !important;
}

.mlk-text-style {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.mlk-text-black {
  color: $blackMlkColor;
}

.mlk-text-black-bold {
  color: $blackMlkColor;
  font-weight: bold;
}

.mlk-text-dark-blue {
  color: $darkBlueMlkColor;
}

.mlk-text-light-blue {
  color: $lightBlueMlkColor;
}

.mlk-text-white {
  color: $whiteMlkColor;
}

.mlk-text-dark-grey {
  color: $darkGreyMlkColor;
}


.mlk-section-why-embassies {
  background: linear-gradient(
      146.85deg,
      $gradientMlkBlueColor 0%,
      $whiteMlkColor 68.66%
  );
}

.why-embassies-text-part-container {
  & {
    @include media-breakpoint-up(lg) {
      gap: 32px;
      width: 630px;
      height: 436px;
    }
  }
}

.mlk-home-page-video-container {
  & {
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 548px;
      height: 364px;
    }
  }

  & > video {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

.mlk-footer-link {
  font-size: 14px;
}

.mlk-bg-light-blue-gradient {
  background-color: $gradientMlkBlueColor;
}

.mlk-bg-light-blue {
  background-color: $lightBlueMlkColor;
}

.mlk-bg-grey {
  background-color: $greyMlkColor;
}

.mlk-bg-light-grey {
  background-color: $lightGreyMlkColor;
}

.mlk-bg-dark-blue {
  background-color: $darkBlueMlkColor;
}

.mlk-bg-linear-gradient-blue-color {
  background-color: $linearGradientBlueMlkColor;
}

.mlk-bg-white {
  background-color: $whiteMlkColor;
  color: $blackMlkColor;
}

.animated-text-container {
  height: 300px;

  @include media-breakpoint-down(lg) {
    padding: 8rem 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 14rem 2rem 14rem 2rem;
  }
}

.mlk-home-page-animated-text {
  width: 100%;
  text-transform: uppercase;
  font-size: 200px;
  font-family: Oswald;
  font-weight: 700;
  color: $whiteMlkColor;
  -webkit-text-stroke: 1.5px $darkBlueMlkColor;
  display: inline-block;
  white-space: nowrap;
}

.mlk-first-animation {
  animation: defilement-first 32s linear infinite;
}

@keyframes defilement-first {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.mlk-input {
  border-radius: 20px;
}

.where-to-find-mlk-amb {
  & {
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 700px;
      height: 447px;
    }
  }
}

.home-page-map-container {
  & {
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 500px;
    }
  }

  max-height: 400px;
  overflow-y: scroll;
}

.home-page-card-how-amb-works {
  width: 400px;
  height: 455px;
  background-color: $linearGradientBlueMlkColor;
}

.how-amb-works-image {
  border-radius: 10px 10px 10px 64px;
  background-size: cover;
  min-height: 250px;
  width: 350px;
}

.mlk-blue-rectangle-container {
  @extend .mlk-sub-title;
  font-weight: 800;
  width: 100%;
  height: 57px;
  padding: 0.5rem 1.5rem;

  /* @include media-breakpoint-only(xs) {
     text-align: center;
     font-size: 20px;
     line-height: 35px;
   }*/
}

.mlk-welcome-how-it-work-item-image {
  @extend .how-amb-works-image;
  background-image: url('/assets/images/accueil.webp');
}

.mlk-celebration-how-it-work-item-image {
  @extend .how-amb-works-image;
  background-image: url('/assets/images/celebration.webp');
}

.mlk-sainte-scene-how-it-work-item-image {
  @extend .how-amb-works-image;
  background-image: url('/assets/images/sainte-cene.webp');
}

.home-stats-items {
  width: 25rem;

  & {
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.mlk-carrousel-item-container {
  width: 80%;

  & {
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.mlk-carrousel-image-container {
  background-size: cover;
  min-height: 300px;
  max-height: 300px;
  width: 100%;
  border-radius: 10px;

  & {
    @include media-breakpoint-up(lg) {
      width: 150rem;
    }
  }
}

.mlk-carrousel-welcome-item-image {
  @extend .mlk-carrousel-image-container;
  background-image: url('/assets/images/accueillir.webp');
}

.mlk-carrousel-share-item-image {
  @extend .mlk-carrousel-image-container;
  background-image: url('/assets/images/partager.webp');
}

.mlk-carrousel-communicate-item-image {
  @extend .mlk-carrousel-image-container;
  background-image: url('/assets/images/communier.webp');
}

.mlk-carrousel-respect-item-image {
  @extend .mlk-carrousel-image-container;
  background-image: url('/assets/images/respecter.webp');
}

.mlk-carrousel-kindness-item-image {
  @extend .mlk-carrousel-image-container;
  background-image: url('/assets/images/bienveillance.webp');
}

.mlk-carrousel-transparency-item-image {
  @extend .mlk-carrousel-image-container;
  background-image: url('/assets/images/transparence.webp');
}

.mlk-carrousel-item-text {
  & {
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }
  }

  width: 450px;
}

.carousel-indicators {
  position: absolute;
  right: 0px;
  top: 24rem;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;

  @include media-breakpoint-down(md) {
    top: 33rem;
  }

  @include media-breakpoint-only(md) {
    top: 37rem;
  }

  button.mlk-carrousel-indicators {
    margin: 0px 7px !important;
  }
}

.mlk-carrousel-container {
  padding: 1rem 0 3rem;
}

.carousel-indicators [data-bs-target] {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: $darkBlueMlkColor;
  opacity: 0.8;
}

.carousel-indicators .active {
  background-color: $greyMlkColor;
}

element.style {
}

.nav-tabs .nav-item {
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

feuille de style user-agent li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.nav-tabs,
.nav-tabs > li > a.nav-link,
.nav-link.active:focus,
.nav-link:hover {
  border: none;
}

.nav-tabs > li > a.nav-link,
.mlk-badge-non-active {
  font-weight: 700;
  font-size: 14px;
  color: $blackMlkColor;
  border-style: none !important;
  border-radius: 50px;
}

.nav-tabs > li > a.nav-link.active {
  @extend .mlk-bg-light-blue;
  @extend .mlk-text-dark-blue;
}

.mlk-rectangle-container {
  padding: 24px 32px;
}

.mlk-card {
  @extend .mlk-rectangle-container;
  background: $whiteMlkColor;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
}

.mlk-border-radius-20-px {
  border-radius: 20px;
}

.mlk-embassies-home-page-card {
  @extend .mlk-card;

  & {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 139px;
    }
  }

  & span.ambassy-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }
}

.mlk-login-register-image-container {
  background-image: url('/assets/images/connexion.webp');
  background-size: 100%;
}

.mlk-login-register-card {
  @extend .mlk-card;
  width: 450px;

  & {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    @include media-breakpoint-only(md) {
      width: 80%;
    }
  }
}

.input-group {
  & .input-group-text {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  & input,
  select,
  textarea {
    border-radius: 20px;
  }
}

textarea:focus,
input:focus {
  outline: none !important;
  color: $blackMlkColor;
}

input,
textarea,
select,
.form-control {
  color: $blackMlkColor;
}

.form-control:focus,
.form-check-input:focus {
  border: 1px solid $darkBlueMlkColor;
  box-shadow: none;
  color: $blackMlkColor;
}

.input-group:focus-within {
  .input-group-text {
    background-color: $darkBlueMlkColor;
    color: $whiteMlkColor;
  }
}

.form-check-input:checked {
  background-color: $darkBlueMlkColor;
  border-color: $darkBlueMlkColor;
}

input[type="checkbox"] + label {
  display: block;
  margin-left: 5px !important;
}

.mlk-divider, hr {
  color: $darkGreyMlkColor;
}

input[type="checkbox"] {
  border-radius: 5px !important;
  width: 1rem;
  height: 1rem;
}

.mlk-link {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: black;
  }
}

.hide-show-password-eye {
  position: absolute;
  top: 38px;
  right: 15px;
  z-index: 6000;
  cursor: pointer;
}

.login-register-form-container {
  min-height: 100vh;
  font-size: 13px;

  & {
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

.mlk-event-inscriptions-container {
  @extend .mlk-rectangle-container;
  border-radius: 0.5rem;
}

.mlk-blue-dashed-container {
  @extend .mlk-event-inscriptions-container;
  border: 2px dashed $darkBlueMlkColor;
}

.mlk-light-blue-container {
  @extend .mlk-event-inscriptions-container;
  border: 2px solid $lightBlueMlkColor;
  background-color: $linearGradientBlueMlkColor;
}

.mlk-light-grey-container {
  @extend .mlk-event-inscriptions-container;
  border: 2px solid $mediumLightGreyMlkColor;
  background-color: $lightGreyMlkColor;
}

.mlk-badge {
  min-width: 82px;
  width: fit-content;
  border-radius: 10px;
  padding: 6px 16px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}

.mlk-badge-red {
  @extend .mlk-badge;
  background-color: $redMlkColor;
  color: $whiteMlkColor;
}

.mlk-badge-white {
  @extend .mlk-badge;
  background-color: $whiteMlkColor;
  color: $blackMlkColor;
}

.mlk-badge-grey {
  @extend .mlk-badge;
  background-color: $mediumLightGreyMlkColor;
  color: $darkGreyMlkColor;
}

.mlk-badge-blue {
  @extend .mlk-badge;
  @extend .mlk-bg-linear-gradient-blue-color;
  @extend .mlk-text-dark-blue;
}

.inscription-or-event-list-container,
.inscription-or-event-add-container,
.inscription-or-event-subscribe-container {
  max-height: 19rem;
  min-width: 40rem;
  max-width: 42rem;
  overflow-y: auto;

  & {
    @include media-breakpoint-down(md) {
      max-height: 25rem;
      overflow-y: scroll;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.mlk-attendees-list-container {
  min-width: 40rem;
  max-width: 42rem;

  & {
    @include media-breakpoint-down(md) {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.mlk-attendees-list {
  max-height: 35rem;
  width: 100%;
  overflow-y: auto;

  & {
    @include media-breakpoint-down(md) {
      max-height: 35rem;
      overflow-y: scroll;
    }
  }
}

.mlk-notifications-indicator {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #ea0b0b;
  position: absolute;
  top: 8px;
  left: 16px;

  & {
    @include media-breakpoint-down(md) {
      top: 8px;
      left: 8px;
    }
  }
}

.mlk-embassy-card {
  @extend .mlk-card;
  padding: 2rem;

  & {
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 27rem;
    }
  }

  .embassy-card-btn {
    width: 9rem;
  }
}

.mlk-notifications {
  border-radius: 20px;
  min-width: 40rem;
  max-width: 40rem;

  & {
    @include media-breakpoint-down(md) {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

.mlk-not-read-notification {
  @extend .mlk-bg-light-blue-gradient;
  @extend .mlk-notifications;
  border: 1px solid $lightBlueMlkColor;
}

.mlk-read-notification {
  @extend .mlk-bg-light-grey;
  @extend .mlk-notifications;
  border: 1px solid $lightGreyMlkColor;
}

.nbr-comments {
  margin-top: -3px;
}

.mlk-modal {
  @extend .mlk-card;
  padding: 2rem;

  .mlk-modal-image {
    width: 4rem;
  }
}

.modal-content {
  border: none;
}

.map-on-embassy-page {
  & {
    @include media-breakpoint-down(md) {
      max-height: 10px;
    }
  }
}

.mlk-img-profile-image {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  object-fit: cover;
}

.mlk-user-info-table {
  td {
    word-break: break-word;
    padding: 2rem;

    & {
      @include media-breakpoint-down(md) {
        padding: 1rem 0.1rem;
        font-size: 13.5px;
      }
    }
  }

  .value-td {
    width: 18rem;
    max-width: 18rem;

    & {
      @include media-breakpoint-down(md) {
        width: 13rem;
        max-width: 13rem;
        padding-right: 10px;
      }
    }
  }
}

.mlk-cursor-pointer {
  cursor: pointer;
}

.mlk-alert {
  @extend .mlk-bg-linear-gradient-blue-color;
  border-radius: 10px;
  border: 2px solid $lightBlueMlkColor;
  padding: 16px;
}

.mlk-user-info-table {
  max-width: 100%;
  overflow-x: scroll;
}

.myCustomModalClass .modal-dialog {
  min-width: 80%;
  min-height: 100%;
}

.mlk-make-gift-banner-image-section {
  background-size: cover;

  width: 100%;
  max-width: 100%;
  background-image: url('/assets/images/dons-mlk.webp');

  & {
    @include media-breakpoint-up(md) {
      height: 15rem;
      max-height: 15rem;
    }

    @include media-breakpoint-up(lg) {
      height: 38rem;
      max-height: 38rem;
    }

    @include media-breakpoint-down(md) {
      height: 15rem;
      max-height: 15rem;
    }

    @include media-breakpoint-down(sm) {
      height: 10rem;
      max-height: 10rem;
    }
  }
}

.home-page-card-gift-page {
  background-color: $linearGradientBlueMlkColor;

  & .mlk-blue-rectangle-container {
    width: 100%;
    max-width: 100%;
  }

  & {
    @include media-breakpoint-up(lg) {
      width: 600px;
      height: 250px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.progress-bar {
  background-color: $darkBlueMlkColor;
}

input[type='tel'] {
  padding-right: 170px !important;
}

.new-event-page-image-container {
  border-radius: 10px 10px 10px 10px;
  background-size: cover;
  min-height: 300px;
  max-height: 300px;
  width: 480px;
  background-image: url('/assets/event.png');
}

.mlk-make-donation-btn-container {
  width: 100%;

  & {
    @include media-breakpoint-up(md) {
      width: fit-content;
    }
  }
}

.mlk-event-amb-container {
  max-width: 100%;
  width: 90rem;
}

.navbar-toggler {
  border: none;
}

.mlk-tooltip {
  @extend .mlk-bg-white;
  border-radius: 10px;
  opacity: 1 !important;
  min-width: 10rem;
  width: 20rem;
  height: fit-content;
  max-width: 20rem;

  & {
    @include media-breakpoint-up(lg) {
      min-width: 20rem;
      width: 20rem;
      height: fit-content;
      max-width: 40rem;
    }
  }

  .tooltip-inner {
    background: none;
    color: $blackMlkColor;
    min-width: 100% !important;
    max-width: 100% !important;
    text-align: left !important;
  }
}

ngx-intl-tel-input {
  width: 100% !important;

  .iti--allow-dropdown {
    width: 100% !important;
  }
}

.image-profile-message {
  @extend .mlk-rectangle-container;
  background: #E7E7E7;
}

/** @deprecated */
// ng-component > div:first-of-type, main > section:first-of-type {
//   min-height: 100dvh !important;
// }


.mlk-button {
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;

  &[disabled] {
    background-color: $greyMlkColor;
    color: white;
  }
}

.mlk-btn-black-outline {
  @extend .mlk-button;
  color: $blackMlkColor;
  background-color: $whiteMlkColor;
  border: 1px solid $blackMlkColor;
}

.mlk-btn-blue-outline {
  @extend .mlk-button;
  color: $darkBlueMlkColor;
  border: 1px solid $darkBlueMlkColor;

  &:hover {
    @extend .mlk-btn-black-outline;
  }
}

.mlk-btn-black-filled {
  @extend .mlk-button;
  color: $whiteMlkColor;
  background-color: $blackMlkColor;
  border: 1px solid $blackMlkColor;
}

.mlk-btn-blue-filled {
  @extend .mlk-button;
  color: $whiteMlkColor;
  border: 1px solid $darkBlueMlkColor;
  background-color: $darkBlueMlkColor;

  &:hover {
    @extend .mlk-btn-black-filled;
  }

  & > a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

.iti__selected-flag {
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  top: 8px;
}

.dropdown-menu.country-dropdown.show {
  max-height: 180px;
  overflow-y: scroll;
  z-index: 6500;
}
