// START OLD CSS -- TO DELETE (SVP NE PAS EFFACER)
$greenMlkBaseColor: #0f3d3e;
$blackMlkBaseColor: #000000;
$whiteSmokeBaseColor: rgb(240, 242, 245, 40%);

html,
body,
app-root,
main {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

app-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mlk-big-logo {
  width: 100%;
}

.mlk-home-page-bg-image-title {
  & {
    @media only screen and (max-width: 500px) {
      font-size: 15px;
    }
  }
}

.mlk-section-tile {
  & {
    @media only screen and (max-width: 500px) {
      font-size: 20px;
    }
  }
}

.mlk-link {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.mlk-link-green {
  @extend .mlk-link;
  color: $greenMlkBaseColor;

  &:hover {
    color: $greenMlkBaseColor;
    font-weight: bold;
  }
}

.mlk-link-black {
  @extend .mlk-link;
  color: $blackMlkBaseColor;

  &:hover {
    color: $blackMlkBaseColor;
    font-weight: bold;
  }
}

.mlk-text-black {
  color: $greenMlkBaseColor;
}

.mlk-text-green {
  color: $greenMlkBaseColor;
}

.mlk-bg-green {
  background-color: $greenMlkBaseColor;
  color: white;
}

.mlk-button {
  padding: 12.5px 20px;
  cursor: pointer;
}

.mlk-button-black {
  @extend .mlk-button;
  border-radius: 50px;
  background-color: $blackMlkBaseColor;
  color: white;

  &:hover {
    color: $blackMlkBaseColor;
    background-color: white;
    border: 1px solid $blackMlkBaseColor;
  }
}

.mlk-button-black-outlined {
  @extend .mlk-button;
  border-radius: 50px;
  color: $blackMlkBaseColor;
  background-color: white;
  border: 1px solid $blackMlkBaseColor;

  &:hover {
    background-color: $blackMlkBaseColor;
    color: white;
  }
}

.mlk-button-green {
  @extend .mlk-button;
  border-radius: 50px;
  background-color: $greenMlkBaseColor;
  color: white;

  &:hover {
    color: $greenMlkBaseColor;
    background-color: white;
    border: 1px solid $greenMlkBaseColor;
    font-weight: bold;
  }
}

.mlk-button-green-outlined {
  @extend .mlk-button;
  border-radius: 50px;
  color: $greenMlkBaseColor;
  background-color: white;
  border: 1px solid $greenMlkBaseColor;
  font-weight: bold;

  &:hover {
    background-color: $greenMlkBaseColor;
    color: white;
  }
}

.mlk-stat-item {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  padding: 2rem;
  background-color: $greenMlkBaseColor;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;

  & {
    @media only screen and (max-width: 500px) {
      width: 150px;
      height: 150px;
      font-size: 15px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1000px) {
      width: 200px;
      height: 200px;
      font-size: 15px;
    }
  }
}

.mlk-search-cover-input {
  border-radius: 20px;
}

.mlk-image {
  border-radius: 10px;
}

.mlk-image-cover-container {
  background: url('/assets/images/cover-image.jpg');
  width: 100%;
  height: 400px;
  background-size: cover;
}

.mlk-search-cover-overlay {
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.mlk-location-cover-icon {
  background-color: $greenMlkBaseColor;
  color: white;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;

  &:hover {
    background-color: $greenMlkBaseColor;
    color: white;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
  }
}

.mlk-search-cover-icon {
  background-color: $greenMlkBaseColor;
  color: white;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;

  &:hover {
    background-color: $greenMlkBaseColor;
    color: white;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }
}

input:focus {
  box-shadow: none;
}

.d-flex {
  gap: 4px;
}

// END OLD CSS -- TO DELETE (SVP NE PAS EFFACER)
